import "./main.js";
import Swiper from "swiper";

/*** Main Hero Slider ***/
const heroMainThumbsSliderConfig = {
  slidesPerView: "auto",
  spaceBetween: 24,
  speed: 3000,
  allowTouchMove: false,
  breakpoints: {
    768: {
      slidesPerView: 5,
    },
  },
  on: {
    init(s) {
      s.el.querySelectorAll(".swiper-slide").forEach((el, indx) => {
        el.addEventListener("click", (e) => {
          // e.preventDefault();
          // window.location = window.location.origin + e.target.getAttribute("href");
          // heroMainSlider.slideTo(indx);
        });
      });
    },
  },
};
const heroMainSliderConfig = {
  loop: true,
  slidesPerView: 1,
  speed: 2500,
  allowTouchMove: false,
  navigation: {
    nextEl: ".main-hero-slider__nextbtn",
    prevEl: ".main-hero-slider__prevbtn",
  },
  autoplay: {
    delay: 8000,
    disableOnInteraction: false,
  },
  on: {
    autoplayTimeLeft(s, time, progress) {
      const thumbs = document.querySelectorAll(".main-hero-slider-thumbs .swiper-slide");
      thumbs.forEach((thumb) => thumb.style.setProperty("--main-hero-slider-progress", `0%`));
      // thumbs[s.activeIndex].style.setProperty("--main-hero-slider-progress", `${(1 - progress) * 100}%`);
      document.querySelector(".main-hero-slider__nextbtn").style.setProperty("--main-hero-slider-slider-progress", 1 - progress);
    },
    slideChange(s) {
      if (window.innerWidth > 992) {
        heroMainThumbs.slideTo(Math.floor(s.realIndex / 5));
      } else {
        heroMainThumbs.slideTo(s.realIndex);
      }
    },
  },
};
const heroMainThumbs = new Swiper(".main-hero-slider-thumbs", heroMainThumbsSliderConfig);
const heroMainSlider = new Swiper(".main-hero-slider", heroMainSliderConfig);
/***/

/*** Portfolio Slider ***/
const portfolioSliderConfig = {
  slidesPerView: "auto",
  spaceBetween: 10,
  speed: 3000,
  navigation: {
    nextEl: ".portfolio-slider__nextbtn",
    prevEl: ".portfolio-slider__prevbtn",
  },
  breakpoints: {
    768: {
      spaceBetween: 20,
    },
    1440: {
      spaceBetween: 40,
    },
  },
};
const portfolioSlider = new Swiper(".portfolio-slider", portfolioSliderConfig);
/***/
